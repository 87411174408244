import {Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

declare let $: any;

@Component({
    selector: 'cmp-sim-edit-pure',
    template: `
        <div class="js-editor"></div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SimEditPureComponent),
            multi: true
        }
    ]
})

export class SimEditPureComponent implements ControlValueAccessor, OnChanges {

    @Input() editorConfig: object;

    private initiated: boolean = false;
    private root: any;

    constructor(private elRef: ElementRef) {}

    propagateChange = (_: any) => {};
    propagateTouch = (_: any) => {};

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        const stringDisabled = isDisabled ? 'disable' : 'enable';
        $(this.root).summernote(stringDisabled);
    }

    writeValue(obj: any): void {
        $(this.root).summernote('code', obj);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['editorConfig'] && changes['editorConfig'].currentValue) {

            this.editorConfig = changes['editorConfig'].currentValue;
            this.root = this.elRef.nativeElement.children[0];

            if (!this.initiated) {
                $(this.root).summernote(this.editorConfig);
                $(this.root).on('summernote.change', (we, contents) => {
                    this.propagateChange(contents);
                });
                this.initiated = true;
            }
        }
    }

}