import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SimEditComponent} from "./sim-edit.component";
import {SimEditPureComponent} from "./sim-edit-pure.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [SimEditComponent, SimEditPureComponent],
    exports: [SimEditComponent, SimEditPureComponent]
})

export class SimEditModule {}