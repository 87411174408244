import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

declare let $: any;

@Component({
    selector: 'cmp-sim-edit',
    template: `
        <div class="js-editor"></div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SimEditComponent),
            multi: true
        }
    ]
})

export class SimEditComponent implements ControlValueAccessor, OnChanges {

    @Input() editorConfig: object;
    @Output() onTextChange: EventEmitter<any>;

    private initiated: boolean = false;
    private root: any;
    private bGate: boolean = false;

    constructor(private elRef: ElementRef) {
        this.onTextChange = new EventEmitter<any>();
    }

    propagateChange = (_: any) => {};
    propagateTouch = (_: any) => {};

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        const stringDisabled = isDisabled ? 'disable' : 'enable';
        $(this.root).summernote(stringDisabled);
    }

    writeValue(obj: any): void {
        this.bGate = true;
        try {
            $(this.root).summernote('code', obj);
        } finally {
            this.bGate = false;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['editorConfig'] && changes['editorConfig'].currentValue) {

            this.editorConfig = changes['editorConfig'].currentValue;
            this.root = this.elRef.nativeElement.children[0];

            if (!this.initiated) {
                $(this.root).summernote(this.editorConfig);
                $(this.root).on('summernote.change', (we, contents) => {
                    this.propagateChange(contents);
                    if (!this.bGate) {
                        this.onTextChange.emit({source: 'user', insertingImage: false});
                    }
                });
                this.initiated = true;
            }
        }
    }

}