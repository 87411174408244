﻿declare let StringView: any;

export function saveFileFromBase64(dataBase64: string, fileName: string, fileType: string): void {
    this.fileType = '';
    let blob = new Blob([StringView.base64ToBytes(dataBase64)], {type: fileType});
    if (window.navigator && window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
        return;
    }
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.getElementsByTagName('body')[0].appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function openFile(fileType?: string): Promise<File> {
    return new Promise<File>(((resolve, reject) => {
        let fi = document.createElement('input');
        fi.type="file";
        if (fileType) {
            fi.accept = fileType;
        }
        fi.focus();
        fi.addEventListener("change", (a) => { return resolve(fi.files[0]); });
        fi.addEventListener("blur", (a) => { return reject(); });
        fi.click();
    }))
}

export function fileToBase64(file: File): Promise<string> {
    return new Promise<string>(resolve => {
        let reader = new FileReader();
        reader.onload = function (e) {
            let bytes = new Uint8Array(e.target['result']);
            let result = StringView.bytesToBase64(bytes);
            return resolve(result);
        };
        reader.readAsArrayBuffer(file);
    })
}